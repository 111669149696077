import css from './sass/style.scss';

require('./js/anims');
require('./js/accordion');
require('./js/base');
require('./js/carousels');
require('./js/cookies');
require('./js/contact-form');
require('./js/custom-select');
require('./js/fixel');
require('./js/fonts');
require('./js/glightbox');
require('./js/init');
require('./js/nav');
// require('./js/map');
require('./js/polyfills');
require('./js/smoothscroll');
require('./js/cookie-consent');
